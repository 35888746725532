import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'uil-home-alt',
        link: '/dashboard',
    },
    {
        id: 2,
        label: 'Customers',
        icon: 'uil-home-alt',
        link: '/customers',
    },     
    {
        id: 3,
        label: 'Domain ',
        icon: 'uil-home-alt',
        link: '/domains',
    },  
    {
        id: 4,
        label: 'Category',
        icon: 'uil-home-alt',
        link: '/categories',
    },       
    {
        id: 5,
        label: 'Configuration',
        icon: 'uil-home-alt',
        link: '/config',
    },        
];