<header id="page-topbar">
  <div class="container-fluid">
    <div class="topnav">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav">
            <!-- Menu data -->
            <ng-container *ngFor="let item of menuItems">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle arrow-none side-nav-link-ref" href="javascript: void(0);" id="topnav-components"
                (click)="onMenuClick($event)" [routerLink]="item.link" role="button">
                  <i class="bx {{item.icon}} me-2"></i>{{ item.label | translate }} <div class="arrow-down"
                    *ngIf="hasItems(item)"></div>
                </a>
                <div class="dropdown-menu" [ngClass]="{'dropdown-mega-menu-xl px-2':  item.subItems.length > 11}" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)" [routerLinkActiveOptions]="{exact: true}">
                  <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                    <a *ngIf="item.subItems.length < 11 && !hasItems(subitem)" class="col dropdown-item side-nav-link-ref"
                      [routerLink]="subitem.link" href="javascript: void(0);" routerLinkActive="active">{{subitem.label | translate }}
                    </a>
                    <div *ngIf="item.subItems.length > 11">
                      <div *ngIf="i % 3 == 0" class="row">
                          <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="subitem.link">{{item.subItems[i].label | translate}}</a></div>
                          <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 1]?.link">{{item.subItems[i + 1]?.label | translate}}</a></div>
                          <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 2]?.link">{{item.subItems[i + 2]?.label | translate}}</a></div>
                      </div>
                  </div>
                    <div class="dropdown" *ngIf="hasItems(subitem)">
                      <a class="dropdown-item" href="javascript: void(0);" (click)="onMenuClick($event)">{{ subitem.label | translate }}
                        <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu">
                        <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                          <a class="dropdown-item side-nav-link-ref" [routerLink]="subSubitem.link" 
                            routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                        </ng-template>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </li>
            </ng-container>
            
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>

<div class="d-md-none">
  <div class="list-group">

    <a *ngFor="let item of menuItems"  href="javascript: void(0);" (click)="onMenuClick($event)" [routerLink]="item.link" role="button"  class="list-group-item list-group-item-action">{{ item.label | translate }}</a>

  </div>
</div>