import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';

import { NgbNavModule, NgbAccordionModule, NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { ErrorInterceptor } from './core/helpers/error.interceptor';



import { FakeBackendInterceptor } from './core/helpers/fake-backend';
// Import the module from the SDK
import { AuthHttpInterceptor, AuthModule, AuthService } from '@auth0/auth0-angular';
import { DateAgoPipe } from './pipes/date-ago.pipe';

import { CustomerService } from './services/customer.service';
import { Router } from '@angular/router';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // Import the module into the application, with configuration
    AuthModule.forRoot({
      domain: 'smccrazy.auth0.com',
      clientId: 'U4Z8Gj8OnjH0UUGWCM2gZ1sJ6x6pbEUB',      
      audience: 'urn:colors',
      scope: 'openid profile email',
      // The AuthHttpInterceptor configuration
      httpInterceptor: {
        allowedList: [
          '/api/*',
          'https://support.bulwarq.com/api/*',
          'https://support.bulwarq.com/support-api/*',
          'https://cpatz3sel5.execute-api.us-east-2.amazonaws.com/*',
          "https://3s4wq3via3.execute-api.us-east-2.amazonaws.com/*"
        ],
      },
    }),    
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LayoutsModule,
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbModule    
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
   // { provide: APP_INITIALIZER, useFactory: initializeBulwarqApp, multi: true, deps: [CustomerService, Router, AuthService] }
    //{ provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
  ],

})
export class AppModule { }
