<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-sm.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="20">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-sm.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="20">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input name="searchBar" (keyup)="searchChange($event)" [(ngModel)]="searchText" type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
          <span class="uil-search"></span>
        </div>
      </form>

    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
          data-toggle="dropdown" aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown">

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input name="search"  (keyup)="searchChange($event)" [(ngModel)]="searchText" type="text" class="form-control" placeholder="My Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="uil-bell"></i>
          
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0 font-size-16">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}}</h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px;">
           
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
              <i class="uil-arrow-circle-right me-1"></i> {{ 'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item waves-effect" *ngIf="auth.user$ | async as user" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" [src]="user.picture" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1 fw-medium font-size-15" >{{ user.name }}</span>
          <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);">
            <i class="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            <span class="align-middle">{{ 'HEADER.LOGIN.PROFILE' | translate}}</span>
          </a>
          <a class="dropdown-item d-block" href="javascript: void(0);">
            <i class="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            <span class="align-middle">{{ 'HEADER.LOGIN.SETTINGS' | translate}}</span>
            <span class="badge badge-soft-success badge-pill mt-1 ms-2">03</span>
          </a>
          <a class="dropdown-item" href="javascript: void(0);" (click)="logout()">
            <i class="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span class="align-middle">{{ 'HEADER.LOGIN.LOGOUT' | translate}}</span>
          </a>
        </div>
      </div>

    </div>
  </div>
</header>